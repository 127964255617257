import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  MdDashboard,
  MdGeneratingTokens,
  MdOutlineFeed,
  MdSettingsInputComponent,
} from "react-icons/md";
import { CgProfile, CgFeed, CgClipboard } from "react-icons/cg";
import { FaWallet, FaRobot } from "react-icons/fa";
import { HiUserGroup } from "react-icons/hi";
import { TbAffiliate } from "react-icons/tb";
import { BsFilesAlt } from "react-icons/bs";
import { GiTwoCoins, GiPolarStar } from "react-icons/gi";
import { BiCurrentLocation } from "react-icons/bi";
import { SiGoogletagmanager, SiBlockchaindotcom } from "react-icons/si";
import {
  RiBankCard2Fill,
  RiListUnordered,
  RiCopperCoinFill,
  RiBankFill,
} from "react-icons/ri";
import { useAclContext } from "../../contexts/acl";
import { useMainContext } from "../../contexts/main";
import { useWindowSize } from "../common/useWindowSize";
import { MOBILE_SIZE } from "../../constants/common";
import { GoChecklist, GoScreenFull } from "react-icons/go";
import { BiTransfer } from "react-icons/bi";
import { IoAnalyticsSharp } from "react-icons/io5";

const useSidebar = () => {
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const { onSidebarChange } = useMainContext();
  const { permissions, loading } = useAclContext();

  const [routes, setRoutes] = useState(null);
  const [urlDirectAccess, setUrlDirectAccess] = useState(null);

  const onRouteClicked = (idx) => {
    if (routes[idx].items) {
      const newRoutes = [...routes];
      routes[idx].open = !routes[idx].open;
      setRoutes(newRoutes);
    } else if (width < MOBILE_SIZE) {
      onSidebarChange();
    }
    navigate(routes[idx].path);
  };

  const onSubRouteClicked = (subrouteIndex, itemIndex) => {
    const items = routes[subrouteIndex];
    const route = items.items[itemIndex];
    navigate(route.path);
    if (width < MOBILE_SIZE) {
      onSidebarChange();
    }
  };

  useEffect(() => {
    if (!loading) {
      const { routes, urlAccess } = getSideRoutes(permissions);
      setRoutes(routes);
      setUrlDirectAccess(urlAccess);
    }
  }, [loading, permissions]);

  return {
    urlDirectAccess,
    routes,
    onRouteClicked,
    onSubRouteClicked,
  };
};

const getSideRoutes = (perms) => {
  /**
   * dashboard access
   */
  const hasDashboardAccess = perms.panel?.read;

  /**
   * users access
   */
  const hasUserAccess = perms.user?.read;

  /**
   * files access
   */
  const hasFilesAccess = perms.export?.read;

  /**
   * cartable access
   */
  const hasCartableAccess = perms.user?.write;

  /**
   * report access
   */
  const hasOrderAccess =
    perms.order?.read || perms.otc?.read || perms.trade?.read;
  const hasCoiningAccess = perms.coining?.read;
  const hasBankingAccess = perms.banking?.read;
  const hasWagesAccess = perms.trace?.read;
  const hasAffiliateAccess = perms.trace?.read || perms.affiliate?.read;
  const hasAddressAccess = perms.address?.read;
  const hasMultisignatureAccess = perms.multiSignature.read;
  const hasAllReportsAccess =
    hasOrderAccess ||
    hasCoiningAccess ||
    hasBankingAccess ||
    hasWagesAccess ||
    hasAffiliateAccess ||
    hasAddressAccess;

  /**
   * setting access
   */
  const hasRolesAccess = perms.role?.read;
  const hasSettingAccess = perms.setting?.read;
  const hasAllSettingAccess = hasSettingAccess || hasRolesAccess;

  const urlAccess = {
    "/login": true,
    "/profile": true,
    "/dashboard": hasDashboardAccess,
    "/users": hasUserAccess,
    "/files": hasFilesAccess,
    "/cartable": hasCartableAccess,
    "/bank-accounts": hasUserAccess,
    "/reports": hasAllReportsAccess,
    "/setting": hasAllSettingAccess,
    "/reports/orders": hasOrderAccess,
    "/reports/coin-transactions": hasCoiningAccess,
    "/reports/bank-transactions": hasBankingAccess,
    "/reports/wages-list": hasWagesAccess,
    "/reports/affiliate-overview": hasAffiliateAccess,
    "/reports/addresses": hasAddressAccess,
    "/reports/transfers": true,
    "/reports/staking": true,
    "/setting/tokens": hasSettingAccess,
    "/setting/pairs": hasSettingAccess,
    "/setting/roles": hasRolesAccess,
    "/setting/user-levels": hasSettingAccess,
    "/setting/robots": hasSettingAccess,
    "/setting/otc": hasSettingAccess,
    "/setting/wages": hasSettingAccess,
    "/setting/available-coins": hasSettingAccess,
    "/setting/general": hasSettingAccess,
    "/setting/coin-list": hasSettingAccess,
    "/setting/transfer": true,
    "/setting/staking": true,
    "/analysis": true, // temporary access
    "/fullnode": hasMultisignatureAccess,
  };

  const routes = [
    { title: "profile", path: "/profile", icon: CgProfile, show: true },
    {
      title: "dashboard",
      path: "/dashboard",
      icon: MdDashboard,
      show: hasDashboardAccess,
    },
    { title: "users", path: "/users", icon: HiUserGroup, show: hasUserAccess },
    {
      title: "analysis",
      path: "/analysis",
      icon: IoAnalyticsSharp,
      show: true,
      // disabled: true
    },
    {
      title: "cartable",
      path: "/cartable",
      icon: CgClipboard,
      show: hasCartableAccess,
    },
    {
      title: "bank-accounts",
      path: "/bank-accounts",
      icon: RiBankFill,
      show: hasUserAccess,
    },
    { title: "files", path: "/files", icon: BsFilesAlt, show: hasFilesAccess },
    {
      title: "fullnode",
      path: "/fullnode",
      icon: GoScreenFull,
      show: hasMultisignatureAccess,
    },
    {
      title: "reports",
      items: [
        {
          title: "orders",
          path: "/reports/orders",
          icon: RiListUnordered,
          show: hasOrderAccess,
        },
        {
          title: "coin-transactions",
          path: "/reports/coin-transactions",
          icon: FaWallet,
          show: hasCoiningAccess,
        },
        {
          title: "bank-transactions",
          path: "/reports/bank-transactions",
          icon: RiBankCard2Fill,
          show: hasBankingAccess,
        },
        {
          title: "wages-list",
          path: "/reports/wages-list",
          icon: CgFeed,
          show: hasWagesAccess,
        },
        {
          title: "affiliate-overview",
          path: "/reports/affiliate-overview",
          icon: TbAffiliate,
          show: hasAffiliateAccess,
        },
        {
          title: "addresses",
          path: "/reports/addresses",
          icon: BiCurrentLocation,
          show: hasAddressAccess,
        },
        {
          title: "transfer",
          path: "/reports/transfers",
          icon: BiTransfer,
          show: true,
        },
        {
          title: "staking",
          path: "/reports/staking",
          icon: GiPolarStar,
          show: true,
        },
      ],
      open: false,
      show: hasAllReportsAccess,
      path: "/reports",
    },
    {
      title: "setting",
      items: [
        {
          title: "overall",
          path: "/setting/general",
          icon: MdSettingsInputComponent,
          show: hasSettingAccess,
        },
        {
          title: "otc",
          path: "/setting/otc",
          icon: SiBlockchaindotcom,
          show: hasSettingAccess,
        },
        {
          title: "wages",
          path: "/setting/wages",
          icon: MdOutlineFeed,
          show: hasSettingAccess,
        },
        {
          title: "available-coins",
          path: "/setting/available-coins",
          icon: GiTwoCoins,
          show: hasSettingAccess,
        },
        {
          title: "user-levels",
          path: "/setting/user-levels",
          icon: RiBankFill,
          show: hasSettingAccess,
        },
        {
          title: "pairs",
          path: "/setting/pairs",
          icon: RiCopperCoinFill,
          show: hasSettingAccess,
        },
        {
          title: "roles",
          path: "/setting/roles",
          icon: SiGoogletagmanager,
          show: hasRolesAccess,
        },
        {
          title: "tokens",
          path: "/setting/tokens",
          icon: MdGeneratingTokens,
          show: hasSettingAccess,
        },
        {
          title: "robots",
          path: "/setting/robots",
          icon: FaRobot,
          show: hasSettingAccess,
        },
        {
          title: "coin-list",
          path: "/setting/coin-list",
          icon: GoChecklist,
          show: hasSettingAccess,
        },
        {
          title: "transfer-setting",
          path: "/setting/transfer",
          icon: GoChecklist,
          show: true,
        },
        {
          title: "staking",
          path: "/setting/staking",
          icon: GiPolarStar,
          show: true,
        },
      ],
      open: false,
      show: hasAllSettingAccess,
      path: "/setting",
    },
  ];

  return {
    urlAccess,
    routes,
  };
};

export default useSidebar;
