import { Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import GlobalLoading from "../components/common/loading/GlobalLoading";
import Dashboard from "./dashboard/Dashboard";
import Profile from "./profile/Profile";
import Login from "./Login";
import { useSetAxiosHeaders } from "../core/hooks/common/useSettings";
import MainLayout from "../components/layout/main/MainLayout";
import Users from "./users/Users";
import AclContextProvider from "../core/contexts/acl";
import Affiliates from "./reports/affiliates/Affiliates";
import AllOrders from "./reports/orders/AllOrders";
import CoinTransaction from "./reports/coin-transactions/CoinTransaction";
import BankTransaction from "./reports/bank-transactions/BankTransaction";
import WagesList from "./reports/wages/WagesList";
import Tokens from "./setting/tokens/Tokens";
import Pairs from "./setting/pairs/Pairs";
import Roles from "./setting/roles/Roles";
import Robots from "./setting/robots/Robots";
import Otc from "./setting/otc/Otc";
import General from "./setting/general/General";
import Files from "./files/Files";
import OrdersDetail from "./reports/orders/OrdersDetail";
import CoinTransactionDetails from "./reports/coin-transactions/CoinTransactionDetails";
import UserDetails from "./users/UserDetails";
import BankTransactionDetails from "./reports/bank-transactions/BankTransactionDetails";
import WagesTrace from "./reports/wages/WagesTrace";
import Reports from "./reports";
import AffiliateReport from "./reports/affiliates/AffiliateReport";
import TraceDetails from "./reports/trace/TraceDetails";
import Settings from "./setting";
import AvailableCoins from "./setting/available-coins/AvailableCoins";
import TokenDetails from "./setting/tokens/TokenDetails";
import TokenOperation from "./setting/tokens/TokenOperation";
import UserLevels from "./setting/user-levels/UserLevels";
import Fiat from "./setting/fiat/Fiat";
import Cartable from "./cartable/Cartable";
import CartableDetails from "./cartable/CartableDetails";
import AddUser from "./users/AddUser";
import BankAccounts from "./bank-accouts/BankAcounts";
import Addresses from "./reports/address/Addresses";
import UserSubDetails from "./users/UserSubDetails";
import { useMainContext } from "../core/contexts/main";
import Fullnode from "./fullnode/Fullnode";
import FullnodeDetails from "./fullnode/FullnodeDetails";
import CoinList from "./setting/coin-list/CoinList";
import Payments from "./reports/payments/Payments";
import TransferSettings from "./setting/transfer/TransferSettings";
import Transfers from "./reports/transfer/Transfers";
import Staking from "./setting/staking/Staking";
import StakingReport from "./reports/staking/Staking";
import StakingDetails from "./reports/staking/StakingDetails";
import Analysis from "./analysis/Analysis";

const Index = () => {
  // setting headers
  useSetAxiosHeaders();
  const { token } = useMainContext();

  return (
    <AclContextProvider>
      <Router basename={process.env.PUBLIC_URL}>
        <Suspense fallback={<GlobalLoading />}>
          <MainLayout>
            {!!token ? (
              <Routes>
                {routes.map((route) => {
                  const { path, Component } = route;
                  return (
                    <Route key={path} path={path} element={<Component />} />
                  );
                })}
                <Route
                  path={"*"}
                  element={<Navigate to="/profile" replace />}
                />
              </Routes>
            ) : (
              <Routes>
                <Route key={"login"} path={"/login"} element={<Login />} />
                <Route path={"*"} element={<Navigate to="/login" replace />} />
              </Routes>
            )}
          </MainLayout>
        </Suspense>
      </Router>
    </AclContextProvider>
  );
};

export const routes = [
  { path: "/dashboard", Component: Dashboard },
  // { path: '/login', Component: Login },
  { path: "/profile", Component: Profile },

  { path: "/users", Component: Users },
  { path: "/users/:id", Component: UserDetails },
  { path: "/users/:id/:tab", Component: UserDetails },
  { path: "/users/:id/:tab/:detailId", Component: UserSubDetails },
  { path: "/users/add-user", Component: AddUser },

  { path: "/files", Component: Files },

  { path: "/cartable", Component: Cartable },
  { path: "/cartable/:id", Component: CartableDetails },

  { path: "/bank-accounts", Component: BankAccounts },

  { path: "/reports", Component: Reports },

  { path: "/reports/orders", Component: AllOrders },
  { path: "/reports/orders/:detailId", Component: OrdersDetail },

  { path: "/reports/payments", Component: Payments },

  { path: "/reports/wages-list", Component: WagesList },
  { path: "/reports/wages-list/:id", Component: WagesTrace },
  { path: "/reports/wages-list/:id/:detailId", Component: TraceDetails },

  { path: "/reports/affiliate-overview", Component: Affiliates },
  { path: "/reports/affiliate-overview/:id", Component: AffiliateReport },
  { path: "/reports/affiliate-overview/:id/:id", Component: TraceDetails },

  { path: "/reports/coin-transactions", Component: CoinTransaction },
  {
    path: "/reports/coin-transactions/:detailId",
    Component: CoinTransactionDetails,
  },

  { path: "/reports/bank-transactions", Component: BankTransaction },
  {
    path: "/reports/bank-transactions/:detailId",
    Component: BankTransactionDetails,
  },

  { path: "/reports/addresses", Component: Addresses },
  { path: "/reports/transfers", Component: Transfers },
  { path: "/reports/staking", Component: StakingReport },
  { path: "/reports/staking/:id", Component: StakingDetails },

  { path: "/setting", Component: Settings },

  { path: "/setting/tokens", Component: Tokens },
  { path: "/setting/tokens/:id", Component: TokenDetails },
  { path: "/setting/tokens/operation", Component: TokenOperation },

  { path: "/setting/wages", Component: Fiat },

  { path: "/setting/otc", Component: Otc },

  { path: "/setting/pairs", Component: Pairs },
  { path: "/setting/roles", Component: Roles },
  { path: "/setting/robots", Component: Robots },
  { path: "/setting/user-levels", Component: UserLevels },
  { path: "/setting/available-coins", Component: AvailableCoins },
  { path: "/setting/general", Component: General },
  { path: "/setting/coin-list", Component: CoinList },
  { path: "/setting/transfer", Component: TransferSettings },
  { path: "/setting/staking", Component: Staking },

  { path: "/fullnode", Component: Fullnode },
  { path: "/fullnode/:id", Component: FullnodeDetails },

  { path: "/analysis", Component: Analysis },
];

export default Index;
