import { useQuery } from "react-query"
import { filterFetch, normalFetch } from "../../fetch-api/get"
import { useQueryContext } from "../../../contexts/query"

export const analysisKeys = {
    getUserBalanecesCoin: 'getUserBalanecesCoin',
    getUserBalanecesFiat: 'getUserBalanecesFiat',
    getAnalysisOverview: 'getAnalysisOverview',
    fiatAnalysisHistory: 'fiatAnalysisHistory',
    coinAnalysisHistory: 'coinAnalysisHistory',
}

export const useGetUserBalancesCoin = ({ excludeId, isExclude }) => {

    let path = `users/admin/analysis/coin`
    if (isExclude && !!excludeId) path += `?search=NotId&query=${excludeId}`

    return useQuery(
        [analysisKeys.getUserBalanecesCoin], () => normalFetch(path),
        {
            select: res => res?.data?.data
        }
    )
}

export const useGetUserBalancesFiat = ({ excludeId, isExclude }) => {

    let path = 'users/admin/analysis/fiat'
    if (isExclude && !!excludeId) path += `?search=NotId&query=${excludeId}`

    return useQuery(
        [analysisKeys.getUserBalanecesFiat], () => normalFetch(path),
        {
            select: res => res?.data?.data
        }
    )
}

export const useGetAnalysisOverview = ({ params, type, flow }) => {

    return useQuery(
        [analysisKeys.getAnalysisOverview, flow, type, params],
        () => filterFetch(params, `${type}/admin/analysis/overview/flows/${flow}`),
        {
            select: res => res?.data?.data
        }
    )
}

export const useGetFiatAnalysisHistory = ({ params, flow }) => {

    return useQuery(
        [analysisKeys.fiatAnalysisHistory, flow, params],
        () => filterFetch(params, `banking/admin/analysis/history/fiat/irt/flows/${flow}`),
        {
            select: res => res?.data?.data
        }
    )
}

export const useGetCoinAnalysisHistory = ({ params, flow, coin }) => {

    return useQuery(
        [analysisKeys.coinAnalysisHistory, flow, coin, params],
        () => filterFetch(params, `coining/admin/analysis/history/coins/${coin}/flows/${flow}`),
        {
            select: res => res?.data?.data
        }
    )
}



export const useGetAnalysisWages = ({ params, search }) => {

    return useQuery(
        [analysisKeys.GET_WAGES, params, search], () => filterFetch(params, `traces/wages${search}`),
        {
            select: res => res?.data?.data
        }
    )
}


export const useGetAnalysisWagesCsv = ({ params, search }) => {

    const { setToast } = useQueryContext()

    return useQuery(
        [analysisKeys.GET_WAGES_CSV, params, search], () => filterFetch(params, `traces/wages${search}`),
        {
            select: res => res?.data?.data,
            enabled: false,
            onSuccess: () => {
                setToast({
                    message: 'added-to-files-successfully',
                    show: true
                })
            }
        }
    )
}